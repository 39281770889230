import { initializeApp } from 'firebase/app';
import { getStorage} from 'firebase/storage'; 

const firebaseConfig = {
    apiKey: "AIzaSyBzktE_NC-leBSYCqIA7aHRtkKfnjS_sko",
    authDomain: "raia-copilot.firebaseapp.com",
    projectId: "raia-copilot",
    storageBucket: "raia-copilot.appspot.com",
    messagingSenderId: "110051470420",
    appId: "1:110051470420:web:c592dceaa69c5832ded1c1",
    measurementId: "G-33Q0CJYGS8"
  };
  
  // Initialize Firebase
  const firebaseApp = initializeApp(firebaseConfig);
  const storage = getStorage(firebaseApp); // Initialize Firebase Storage

  export { storage };