import * as React from "react";
import {useState, useEffect, useRef, useContext} from 'react';
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { ThemeContext } from '../../context/ThemeContext';

// import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
// import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import Switch from '@mui/material/Switch';

import CloseIcon from "@mui/icons-material/Close";
import PhoneIcon from "@mui/icons-material/Phone";
import SettingsIcon from "@mui/icons-material/Settings";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  borderRadius: "24px",
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const StyledTab = styled(Tab)(({ theme }) => ({
  alignItems: "flex-start",
  // width: "220px",
  // border: "1px solid",
  textTransform: "none",
  borderRadius: "12px",
  // padding: "24px",
  transition: "all 0.2s ease-in-out",
  "& svg": {
    fontSize: 20,
  },
  "&:hover": {},
  "&.Mui-selected": {
    backgroundColor: "#efefef",
    // boxShadow: theme.shadows[3],
  },
}));

const contentStyle = {
  flexGrow: 1, 
  bgcolor: 'background.paper', 
  display: 'flex', 
};

const tabPanelStyle = {
  minWidth: "500px",
  position: "relative"
};

const listStyle = {
  py: 0,
  width: "100%",
  backgroundColor: "background.paper",
};

const listItemStyle = {
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  paddingTop: 1,
  paddingBottom: 1,
}

export default function SettingsModal({ open, handleClose}) {
  const [tabId, setTabId] = useState(0);
  const {theme, toggleTheme } = useContext(ThemeContext);

  const [themeId, setThemeId] = useState(theme === 'dark' ? 0 : 1);
  const [language, setLanguage] = useState(0);

  useEffect(() => {
    if ((theme === 'dark' && themeId === 1) || (theme === 'light' && themeId === 0)) {
      toggleTheme();
    }
  }, [themeId, theme, toggleTheme]);

  const handleChange = (event, newTablId) => {
    setTabId(newTablId);
  };

  return (
    <BootstrapDialog
      fullWidth={false}
      maxWidth="md"
      onClose={() => handleClose()}
      open={open}
    >
      <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
        Settings
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={() => handleClose()}
        sx={(theme) => ({
          position: "absolute",
          right: 8,
          top: 8,
          color: theme.palette.grey[500],
        })}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        <Stack direction="row" gap={2} sx={contentStyle}>
          <Tabs
            orientation="vertical"
            value={tabId}
            onChange={handleChange}
            sx={{
              borderRight: 1,
              borderColor: "divider",
              height: "auto",
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "flex-start",
            }}
          >
            <StyledTab
              label={
                <Stack direction="row" alignItems="center" gap={1}>
                  <SettingsIcon />
                  <Box>
                    <Typography whiteSpace="nowrap">General</Typography>
                  </Box>
                </Stack>
              }
              {...a11yProps(0)}
            />
            <StyledTab
              label={
                <Stack direction="row" alignItems="center" gap={1}>
                  <SettingsIcon />
                  <Box>
                    <Typography whiteSpace="nowrap">Personalization</Typography>
                  </Box>
                </Stack>
              }
              {...a11yProps(1)}
            />
            <StyledTab
              label={
                <Stack direction="row" alignItems="center" gap={1}>
                  <SettingsIcon />
                  <Box>
                    <Typography whiteSpace="nowrap">Speech</Typography>
                  </Box>
                </Stack>
              }
              {...a11yProps(2)}
            />
            <StyledTab
              label={
                <Stack direction="row" alignItems="center" gap={1}>
                  <SettingsIcon />
                  <Box>
                    <Typography whiteSpace="nowrap">Data controls</Typography>
                  </Box>
                </Stack>
              }
              {...a11yProps(3)}
            />
            <StyledTab
              label={
                <Stack direction="row" alignItems="center" gap={1}>
                  <SettingsIcon />
                  <Box>
                    <Typography whiteSpace="nowrap">Builder profile</Typography>
                  </Box>
                </Stack>
              }
              {...a11yProps(4)}
            />
            <StyledTab
              label={
                <Stack direction="row" alignItems="center" gap={1}>
                  <SettingsIcon />
                  <Box>
                    <Typography whiteSpace="nowrap">Connected apps</Typography>
                  </Box>
                </Stack>
              }
              {...a11yProps(5)}
            />
            <StyledTab
              label={
                <Stack direction="row" alignItems="center" gap={1}>
                  <SettingsIcon />
                  <Box>
                    <Typography whiteSpace="nowrap">Security</Typography>
                  </Box>
                </Stack>
              }
              {...a11yProps(6)}
            />
          </Tabs>
          <TabPanel value={tabId} index={0}>
            <Box sx={tabPanelStyle}>
              <List sx={listStyle}>
                <ListItem>
                  <Box sx={listItemStyle}>
                    <Typography>Theme</Typography>
                    <FormControl variant="standard" sx={{ m: 0, minWidth: 120 }}>
                    <Select
                      value={themeId}
                      onChange={(e) => setThemeId(e.target.value)} // `e.target.value` will capture the selected value
                    >
                      <MenuItem value={0}>Dark</MenuItem>
                      <MenuItem value={1}>Light</MenuItem>
                    </Select>
                    </FormControl>
                  </Box>
                </ListItem>
                <Divider component="li" />
                <ListItem>
                  <Box sx={listItemStyle}>
                    <Typography>Always default to Admin Mode</Typography>
                    <Switch />
                  </Box>
                </ListItem>
                <Divider component="li" />
                {/* <ListItem>
                  <Box sx={listItemStyle}>
                    <Typography>Language</Typography>
                    <FormControl variant="standard" sx={{ m: 0, minWidth: 120 }}>
                      <Select
                        value={language}
                        onChange={(v) => setLanguage(v)}
                      >
                        <MenuItem value={0}>Auto-detect</MenuItem>
                        <MenuItem value={1}>English</MenuItem>
                        <MenuItem value={2}>Italian</MenuItem>
                        <MenuItem value={3}>Polish</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </ListItem>
                <Divider component="li" /> */}
                {/* <ListItem>
                  <Box sx={listItemStyle}>
                    <Typography>Archived chats</Typography>
                    <Button variant="outlined">Manage</Button>
                  </Box>
                </ListItem>
                <Divider component="li" />
                <ListItem>
                  <Box sx={listItemStyle}>
                    <Typography>Archived all chats</Typography>
                    <Button variant="outlined">Archive all</Button>
                  </Box>
                </ListItem>
                <Divider component="li" />
                <ListItem>
                  <Box sx={listItemStyle}>
                    <Typography>Delete all chats</Typography>
                    <Button variant="contained">Delete all</Button>
                  </Box>
                </ListItem> */}
              </List>
            </Box>
          </TabPanel>
          <TabPanel value={tabId} index={1}>
            <Box sx={tabPanelStyle}>Personalization</Box>
          </TabPanel>
          <TabPanel value={tabId} index={2}>
            <Box sx={tabPanelStyle}>Speech</Box>
          </TabPanel>
          <TabPanel value={tabId} index={3}>
            <Box sx={tabPanelStyle}>Data controls</Box>
          </TabPanel>
          <TabPanel value={tabId} index={4}>
            <Box sx={tabPanelStyle}>Builder profile</Box>
          </TabPanel>
          <TabPanel value={tabId} index={5}>
            <Box sx={tabPanelStyle}>Connected apps</Box>
          </TabPanel>
          <TabPanel value={tabId} index={6}>
            <Box sx={tabPanelStyle}>Security</Box>
          </TabPanel>
        </Stack>
        {/* </Box> */}
      </DialogContent>
      <DialogActions>
        {/* <Button autoFocus onClick={() => handleClose()}>
                    Save changes
                </Button> */}
      </DialogActions>
    </BootstrapDialog>
  );
}
