import React from 'react';

const FileIcon = ({ fileName, style }) => {
  const getFileIconClass = (fileName) => {
    if (!fileName)
      return;

    const extension =  fileName!==''?fileName.split('.').pop().toLowerCase():'';

    switch (extension) {
      case 'pdf':
        return 'far fa-file-pdf';
      case 'doc':
      case 'docx':
        return 'far fa-file-word';
      case 'xls':
      case 'xlsx':
        return 'far fa-file-excel';
      case 'ppt':
      case 'pptx':
        return 'far fa-file-powerpoint';
      case 'txt':
        return 'far fa-file';
      case 'jpg':
      case 'jpeg':
      case 'png':
        return 'far fa-file-image';
      case 'zip':
      case 'rar':
        return 'far fa-file-archive';
      case 'json':
      case 'cs':
      case 'cpp':
      case 'js':
      case 'css':
      case 'ts':
      case 'html':
        return 'far fa-file-code';
      default:
        return 'far fa-file-alt'; // default icon for unknown types
    }
  };

  return (
    <i className={`${getFileIconClass(fileName)} icon-sm`} style={style}></i>
  );
};

export default FileIcon;